// import logoDark from "@/images/logo.png";
import logoDark from "@/images/images/image.png";
import slideBackground from "@/images/images/HomeCover.png";
// import slideOneImage1 from "@/images/slider/slider-1.jpg";
// import slideOneImage2 from "@/images/slider/slider-2.jpg";
// import slideOneImage3 from "@/images/slider/slider-3.jpg";
import slideOneImage4 from "@/images/slider/slider-4.jpg";
import slideOneImage5 from "@/images/slider/slider-5.jpg";
import serviceImage1 from "@/images/DDImages/services/exchange-offer.png";
import serviceImage2 from "@/images/DDImages/services/inverter-solution.png";
import serviceImage3 from "@/images/DDImages/services/battery.png";
import serviceImage4 from "@/images/DDImages/services/servo-solution.png";
// import serviceImage2 from "@/images/services/service-2.jpg";
// import serviceImage3 from "@/images/services/service-3.jpg";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/DDImages/upanddown/servo-oil-1-(271-x-233).png";
import galleryOne2 from "@/images/DDImages/upanddown/inverter-(328-x-283)-2.png";
import galleryOne3 from "@/images/DDImages/upanddown/addo-battery-(348-x-363).png";
import galleryOne4 from "@/images/DDImages/upanddown/addo-(348-x-433).png";
import galleryOne5 from "@/images/DDImages/upanddown/inverter-(328-x-283)-1.png";
import galleryOne6 from "@/images/DDImages/upanddown/servo-oil-2-271-x-233.png";

import testimonial1 from "@/images/testimonial/testimonial-1.jpg";
import testimonial2 from "@/images/testimonial/testimonial-2.jpg";
import testimonial3 from "@/images/testimonial/testimonial-3.jpg";
import testimonial4 from "@/images/testimonial/testimonials.jpeg";

import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import blog4 from "@/images/blog/blog-4.jpg";
import blog5 from "@/images/blog/blog-5.jpg";
import blog6 from "@/images/blog/blog-6.jpg";
import team1 from "@/images/team/team-1.jpg";
import team2 from "@/images/team/team-2.jpg";
import team3 from "@/images/team/team-3.jpg";
import team4 from "@/images/team/team-4.jpg";
import comment1 from "@/images/comment-1.jpg";
import comment2 from "@/images/comment-2.jpg";

import Product1 from "@/images/DDImages/Products/addo-1.png";
import Product2 from "@/images/DDImages/Products/add0-2.png";
import Product3 from "@/images/DDImages/Products/addo-3.png";
import Product4 from "@/images/DDImages/Products/addo-4.png";
import Product5 from "@/images/DDImages/Products/addo-5.png";
import Product6 from "@/images/DDImages/Products/addo-6.png";
import Product7 from "@/images/DDImages/Products/addo-7.png";
import Product8 from "@/images/DDImages/Products/addo-8.png";

export const Logo = {
  dark: logoDark,
};

export const NavLinksData = [
  {
    name: "Home",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/"
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2"
    //   }
    // ]
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Our Products",
    url: "/service",
    // subItems: [
    //   {
    //     name: "Services",
    //     url: "/service"
    //   },
    //   {
    //     name: "Service Details",
    //     url: "/service-details"
    //   }
    // ]
  },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog Page",
  //       url: "/blog"
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-details"
  //     }
  //   ]
  // },
  {
    name: "Contact",
    url: "/contact",
  },
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "Laliguras-12,Pokhara",
    url: "#",
  },
  {
    icon: "icon-phone-call",
    tagLine: "Call Now",
    text: "980-2852590",
    url: "https://wa.me/9779802852590",
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#",
  },
];

export const SlideOneData = [
  {
    backgroundImage: slideBackground,
    title: "Welcome to D&D Trading Concern ",
    text: "Your Reliable Source for Inverter Batteries & Engine Oil",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
  // {
  //   backgroundImage: slideOneImage2,
  //   title: "Installation Services & Repair",
  //   text: "Reliable Air Conditioning",
  //   button: "GET A FREE QUOTE",
  //   url: "/contact",
  // },
  // {
  //   backgroundImage: slideOneImage3,
  //   title: "Heating and Air Conditioning Specialist",
  //   text: "Choose the Best Company",
  //   button: "GET A FREE QUOTE",
  //   url: "/contact",
  // },
];

export const SlideTwoData = [
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialis",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage5,
    title: "Keep your family happy this winter, and warm.",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialist",
    button: "GET A FREE QUOTE",
    url: "/contact",
  },
];

export const FeatureOneData = [
  {
    icon: "icon-wallet",
    title: "Low Maintenance",
  },
  {
    icon: "icon-diploma",
    title: "Long-Lasting",
  },
  {
    icon: "icon-big_clock",
    title: "Easy Recovery",
  },
  {
    icon: "icon-handshake",
    title: "Powerful & Trustworthy",
  },
  {
    icon: "icon-mechanic",
    title: "Emergency Service",
  },
];

export const AboutOneData = {
  title: "Heating and air conditioning repair and installation company",
  tagLine: "Welcome to D&D Trading",
  content:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    url: "/about",
    label: "LEARN MORE",
  },
};

export const ServiceOneData = {
  title: "Our services",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Exchange Offers",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Inverter Solutions",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: serviceImage3,
      icon: "icon-ac",
      title: "Battery Solutions",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: serviceImage4,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
  ],
};

export const CallToActionOneData = {
  title:
    "Contact us for your inverter or battery installation. We are available 24/7",
  button: {
    url: "/contact",
    label: "CONTACT US",
  },
};

export const CallToActionTwoData = {
  left: {
    text: "Air Conditioning Services",
    title: "Cool it down",
  },
  right: {
    text: "Heating Services",
    title: "Heat it up",
  },
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "Numbers Speakers.",
  posts: [
    {
      count: 8870,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 4760,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 9032,
      text: "Air Conditioning Repairs and Install",
    },
  ],
};

export const GalleryOneData = [
  {
    title: "Maintenance and Repair",
    image: galleryOne1,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne2,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne3,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne4,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne5,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne6,
    url: "#",
  },
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial4,
      content:
        "D&D Trading Concern has been my go-to destination for all things related to inverter batteries and engine oil. Their products are of exceptional quality, and their team's knowledge is truly impressive. Thanks to them, I've experienced uninterrupted power",
      name: "- Bhuwan Poudel",
      designation: "CEO",
    },
    {
      image: testimonial4,
      content:
        "Their inverter batteries are a lifesaver during power outages, and their engine oils have significantly extended the life of my vehicles. It's rare to find such a reliable supplier with top-notch products with great service",
      name: "-Sujan Shrestha",
      designation: "Developer",
    },
    {
      image: testimonial4,
      content:
        "The engine oils have improved fuel efficiency and reduced maintenance time for me. It's a pleasure working with a D&D that understands the importance of quality.",
      name: "- Tek Bahadur K.C",
      designation: "Director",
    },
    // {
    //   image: testimonial4,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Della Barnett",
    //   designation: "CEO",
    // },
    // {
    //   image: testimonial4,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Max Dunn",
    //   designation: "Developer",
    // },
    // {
    //   image: testimonial4,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
    //   name: "Alberta Potter",
    //   designation: "Director",
    // },
  ],
};

export const FooterBottomData = {
  social: [
    // {
    //   icon: "fa fa-twitter",
    //   url: "#",
    // },
    {
      icon: "fa-brand fa-facebook-f",
      url: "https://www.facebook.com/profile.php?id=100083124022699",
    },
    {
      icon: "fa-brand fa-instagram",
      url: "https://www.instagram.com/ddtradingconcern_/",
    },
    {
      icon: "fa-brand fa-music",
      url: "https://www.tiktok.com/@dnd_trading_concern",
    },
  ],
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "Laliguras-12,Pokhara",
    url: "#",
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "khadka.dipen295@gmail.com",
    url: "mailto:khadka.dipen295@gmail.com",
  },
  {
    icon: "icon-clock",
    tagLine: "Open Hours",
    text: " Mon-Sat: 9am - 6pm",
    url: "#",
  },
];

export const FooterWidgets = {
  about: {
    title: "About D&D Trading",
    text: "D&D Trading: With nearly a year of industry expertise, we supply top-tier inverter batteries and premium engine oils. Our team is available 24/7 for your installation and setup needs.",
    tagLine: "Contact Us",
    phone: "+977 980-2852590",
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "Home",
        url: "/",
      },
      {
        lable: "About",
        url: "/about",
      },
      {
        lable: "Product",
        url: "/service",
      },
      {
        lable: "Contact",
        url: "/contact",
      },
      // {
      //   lable: "Terms & Conditions",
      //   url: "#",
      // },
    ],
  },
  linkTwo: {
    menu: [
      {
        lable: "Careers",
        url: "#",
      },
      {
        lable: "Privacy Policy",
        url: "#",
      },
      {
        lable: "Change Location",
        url: "#",
      },
      {
        lable: "Expert Tips",
        url: "#",
      },
      {
        lable: "Terms & Conditions",
        url: "#",
      },
    ],
  },
};

export const BlogOneData = {
  title: "Our latest news",
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
  ],
};

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "How does a ductless heat pump work?",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Simple hack to improve A/C efficiency",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Preparing your home before vacation",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog4,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Heating and cooling safety issues",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog5,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Don’t neglect your outdoor unit",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
    {
      image: blog6,
      date: "07 Nov",
      author: "admin",
      commentCount: 3,
      title: "Have your system professionally inspected",
      url: "/blog-details",
      text: "There are many variations passages of lorem ipsum available but the majority have suffered alteration.",
    },
  ],
};

export const VideoOneData = {
  title: "Watch how we work",
  id: "8DP4NgupAhI",
};

export const AboutTwoData = {
  caption: " 20 <span>years experience</span> ",
  blockTitle: {
    title:
      "We believe in delivering nothing but the best. When you choose us, you choose reliability.",
    tagline: "Why choose us",
  },
  lists: [
    {
      text: " We check to ensure our services meet the highest industry standards.",
    },
    {
      text: " 24/7 availability for your needs.",
    },
    {
      text: " We provide installation and setup services",
    },
    {
      text: " 24 / 7 availability for all emergency services",
    },
    {
      text: " Your satisfaction is our priority so we strive to build lasting relationships.",
    },
    {
      text: " We also offer exchange services where you can exchange old batteries.",
    },
  ],
  offer: {
    tagline: "10% off",
    text: "on our all services for senior citizens.",
  },
};

export const TeamOneData = {
  title: "Meet The Team",
  posts: [
    {
      image: team1,
      name: "Effie Green",
      designation: "Manager",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team2,
      name: "Catherine Lane",
      designation: "Worker",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team3,
      name: "Augusta Wells",
      designation: "Developer",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team4,
      name: "Kyle Fuller",
      designation: "Founder",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
  ],
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york",
  },
  summery:
    "There are many variations of passages of lorem ipsum available, but the majorify have suffered alteration in some form by injected humour, or randmised words which don't look even slightly believable.",
  button: {
    lable: "LEARN MORE",
    url: "/about",
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again",
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool",
    },
  ],
};

export const AboutFourData = {
  blockTitle: {
    title: "We are trusted by more than <span>100</span> clients",
    text1:
      "With years of almost a year of experience in the industry, D&D Trading Concern has established itself as a leading supplier of high-quality inverter batteries and engine oils. ",
    text2:
      "We understand the importance of efficient power storage and proper lubrication, whether it's for your home or business. Our team is available 24/7 to provide any installation and setup services.",
  },
};

export const BenefitOneData = {
  title: "Our benefits",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Emergency Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-wallet",
      title: "Quick Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-diploma",
      title: "Installation Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-mechanic",
      title: "Satisfaction Guarantee",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
  ],
};

export const ServiceSidebarListData = [
  {
    icon: "icon-snow",
    title: "Cooling Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
  {
    icon: "icon-fire",
    title: "Heating Services",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Other Services",
    content: {
      icon: "icon-support",
      title: "<span>Contact with us </span> 980-2852590",
    },
  },
  {
    icon: "icon-building",
    title: "Commercial Services",
    content: {
      icon: "icon-building",
      title: "<span>Contact with us </span> 980-2852590",
    },
  },
];

export const CommentPostData = [
  {
    name: "David Martin",
    image: comment1,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet.",
  },
  {
    name: "Jessica Brown",
    image: comment2,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet.",
  },
];

export const ProductData = {
  title: "Our Product",
  posts: [
    {
      image: Product1,
      icon: "icon-snow",
      title: "Exchange Offers",
      text: "We Provide Many Exchange Offers.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product2,
      icon: "icon-fire",
      title: "Inverter Solutions",
      text: "We Provide All Kind of Inverter Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product3,
      icon: "icon-ac",
      title: "Battery Solutions",
      text: "We Provide All Kind of Battery Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product4,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "We Provide All Kind of Engine Oil Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product5,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "We Provide All Kind of Engine Oil Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product6,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "We Provide All Kind of Inverter Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product7,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "We Provide All Kind of Inverter Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
    {
      image: Product8,
      icon: "icon-ac",
      title: "Engine Oils",
      text: "We Provide All Kind of Inverter Solution.",
      button: {
        label: "BUY NOW",
        url: "/service-details",
      },
    },
  ],
};
